<template>
  <div class="form-template-container" ref="editor">
    <div class="dragable-container">
      <!-- <select class="form-control" @select="AddField">
        <option value="">בחר שדה</option>
        <option
          v-for="(option, idx) in fieldOptions"
          :key="idx"
          :value="option.Value"
        >
          {{ option.Text }}hheight
        </option>
      </select> -->
      <div class="buttons-container">
        <!-- <div>
          <button v-for="(option, idx) in fieldOptions" :key="idx" @click="AddField(idx)" style="width: 170px">
            <span>
              {{ option.Text }}
            </span>
            <font-awesome-icon icon="fa-solid fa-file-signature" />
          </button>
        </div> -->
        <button @click="saveTemplate" :disabled="fields.length < 1 || inProgress"
          :class="fields.length < 1 || inProgress ? 'disabled' : ''" style="width: 85px">
          <span v-if="!inProgress">
            <span v-if="isUpdate">עדכן </span>
            <span v-if="!isUpdate">שמור </span>
            <font-awesome-icon icon="fa-solid fa-floppy-disk" />
          </span>
          <span v-if="inProgress">
            שומר...
          </span>
        </button>
      </div>
    </div>
    <div v-if="images.length < 1">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
    <div class="right-side">
      <div class="buttons">
        <h2>רשימת שדות:</h2>
        <button :class="getBackgroundColor(option.assigneeNumber)" v-for="(option, idx) in fieldOptions" :key="idx"
          @click="AddField(idx)">
          <span>
            {{ option.Text }}
          </span>
          <font-awesome-icon icon="fa-solid fa-file-signature" />
        </button>
      </div>
      <div v-if="currField" class="details">
        <h2>:מאפייני שדה</h2>
        <div>סוג שדה: {{ currField.assigneeNumber === 3 ? 'חתימת מייצג' : currField.assigneeNumber === 2 ? 'חותם 2' :
          'חותם 1' }}</div>
        <div class="input-container"><input id="width" @focus="$event.target.select()" type="number"
            v-model="currField.x"> <label for="width">:מרחק מהקצה השמאלי</label></div>
        <div class="input-container"><input id="width" @focus="$event.target.select()" type="number"
            v-model="currField.y"> <label for="width">:מרחק מהקצה העליון</label></div>
        <div class="input-container"><input id="width" @focus="$event.target.select()" type="number"
            v-model="currField.width"> <label for="width">:רוחב</label></div>
        <div class="input-container"><input id="width" @focus="$event.target.select()" type="number"
            v-model="currField.height"> <label for="width">:גובה</label></div>
        <!-- <div>type: {{ currField.type }}</div> -->
      </div>
    </div>
    <div class="all-imgs-container">
      <DraggableContainer class="all-imgs-items">
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <div class="all-imgs-item" v-for="(image, index) in images" :key="index" :ref="`imgNumber${index + 1}`"
          style="position: relative;">
          <img :src="`data:image/jpg;base64, ${image.data}`" alt="image" />
          <Vue3DraggableResizable v-if="getAttributes(index + 1) && images.length && showAttributes"
            :class="getBackgroundColor(field.assigneeNumber)" v-for="(field, idx) in getAttributes(index + 1)"
            :key="idx" :initW="field.width || 150" :initH="field.height || 100" :x="field.x" :y="field.y"
            :w="field.width" :h="field.height" :active="active" :draggable="true" :resizable="true" :parent="true"
            :lockAspectRatio="true" @resize-end="handleResizable(idx, $event)"
            @drag-end="handleDraggable(field.id, $event)">
            <div class="draggable-resizable-field">
              <span> גרור והתאם שדה </span>

              <button class="remove-button" @click="removeField(idx)">x</button>
            </div>
          </Vue3DraggableResizable>
          <Vue3DraggableResizable style="background-color: transparent;" v-if="getPastSignedAttributes(index + 1)"
            v-for="(field, idx) in getPastSignedAttributes(index + 1)"
            :key="idx" :initW="field.width || 150" :initH="field.height || 100" :x="field.x" :y="field.y"
            :w="field.width" :h="field.height" :draggable="false" :resizable="false" :parent="true"
            :lockAspectRatio="true">
            <div :style="{
              background: ` url('${field.value}') no-repeat center `,
            }" class="draggable-resizable-field">
            </div>
          </Vue3DraggableResizable>
        </div>
      </DraggableContainer>
    </div>
    <sweet-modal icon="success" ref="completed" :blocking="true" :hide-close-button="true">
      <p>
        שדות החתימה מוקמו בהצלחה!
        <br />
        <br />
        <span v-if="!isUpdate">ניתן לסגור דף זה!</span>
      </p>
    </sweet-modal>
    <sweet-modal :title="title" ref="modal" :blocking="true" :hide-close-button="true" class="opening-modal">
      <p style="font-size: 30px; margin-bottom: 30px">💬</p>
      {{ message }}
      <template #button>
        <button @click="closeModal()" class="SweetModal__Button center clickable">
          התחל
        </button>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import DocumentService from "@/services/DocumentService.js";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import { SweetModal } from "sweet-modal-vue";
import axios from "axios";

export default {
  name: "FormTemplate",
  data() {
    return {
      active: false,
      fieldOptions: [],
      fields: [],
      images: [],
      currField: null,
      showAttributes: false,
      pastSignedAttributes: [],
      activeAttr: null,
      activeElement: null,
      isSelectOpen: false,
      isUpdate: false,
      docIdInTemplate: null,
      queryAssigneeNumber: null,
      queryAssigneeName: null,
      inProgress: false,
      message:
        'יש לבחור את מיקום החתימה ע"י לחיצה על כפתור מיקום חתימה בחלקו הימני העליון של המסמך ולאחר מכן יש לשמור את המסמך בכפתור השמירה בחלקו השמאלי העליון של המסמך',
    };
  },
  components: {
    SweetModal,
    Vue3DraggableResizable,
  },
  created() {
    this.isUpdate = this.$route.name === "FormUpdateTemplate";
    this.queryAssigneeNumber = +this.$route.query.assigneeNumber;
    this.queryAssigneeName = this.$route.query.assigneeName;
    switch (this.queryAssigneeNumber) {
      case 1:
        this.fieldOptions = [{ Value: "signature", Text: this.queryAssigneeName || "חותם 1", assigneeNumber: 1 }];
        break;
      case 2:
        this.fieldOptions = [{ Value: "signature", Text: this.queryAssigneeName || "חותם 2", assigneeNumber: 2 }];
        break;
      case 3:
        this.fieldOptions = [{ Value: "signature", Text: this.queryAssigneeName || "חתימת מייצג", assigneeNumber: 3 }];
        break;

      default:
        this.fieldOptions = [
          { Value: "signature", Text: "חותם 1", assigneeNumber: 1 },
          { Value: "signature", Text: "חותם 2", assigneeNumber: 2 },
          // { Value: "signature", Text: "חתימת מייצג", assigneeNumber: 3 }
        ]
        break;
    }
  },
  beforeCreate() {
    axios.defaults.headers.common["x-access-token"] =
      this.$route.params.accessToken;
    this.isUpdate = this.$route.name === "FormUpdateTemplate";
    if (!this.isUpdate) {
      DocumentService.getDocument(this.$route.params.id, this.$route.query.assigneeToken).then((res) => {
        const doc = res.data;
        console.log("doc:", doc);
        this.images = doc.images;
        this.attributes = doc.attributes;
        this.pastSignedAttributes = doc.pastSignedAttributes;
        setTimeout(() => {
          this.showAttributes = true;
        })
        this.$refs.modal.open();
      });
    } else {
      DocumentService.getTemplate(this.$route.params.id).then((res) => {
        const tem = res.data;
        console.log("tem:", tem);
        const doc = tem.document;
        this.docIdInTemplate = doc.id;
        this.images = doc.images;
        this.attributes = doc.attributes;

        tem.assignees[0].attributes.forEach((field, index) => {
          if (field.page > 1 && doc.images[index] && doc.images[index].size) {
            // field.y = field.y + (field.page - 1) * doc.images[index].size.height;
          }
          // delete field.page;
        });
        this.fields = tem.assignees[0].attributes;
        this.fields.forEach((f) => f.id = this.getAttrRandomId());
        if (tem.assignees[1] && tem.assignees[1].attributes) {
          tem.assignees[1].attributes.forEach((a) => this.fields.push(a));
        }
        setTimeout(() => {
          this.showAttributes = true;
        })
        if (!this.isUpdate) this.$refs.modal.open();
      });
    }
  },
  computed: {
    isDocumentValid() {
      return (
        this.attributes.filter((a) => a.isValid).length ===
        this.attributes.length
      );
    },
  },
  methods: {
    print(val) {
      console.log(val);
    },
    getBackgroundColor(assigneeNumber) {
      return assigneeNumber === 1 ? 'ochre' : assigneeNumber === 2 ? 'light-gray' : 'light-blue';
    },
    getUnsetField() {
      const images = [...document.querySelectorAll('.all-imgs-item')]
      const yOffset = window.pageYOffset;
      let pageIdx = 0;
      // let res = yOffset;
      let acc = 0;
      for (let i = 0; i < images.length; i++) {
        if (!yOffset) {
          pageIdx = 1;
          break;
        }
        if (acc + 0.7 * images[i].offsetHeight < yOffset) {
          acc += images[i].offsetHeight;
        } else {
          pageIdx = i + 1;
          break;
        }
        // res -= +this.images[i].size.height;
        // if (res * 0.70 >= yOffset * -1) {
        //   pageIdx = i + 2;
        //   break;
        // }
        // if (res < 0 || i === this.images.length - 1) {
        //   pageIdx = i + 1;
        //   break;
        // } 
      }
      return {
        x: 100,
        y: 300 + (yOffset - acc),
        width: 150,
        height: 100,
        page: pageIdx,
        id: this.getAttrRandomId()
      };
    },
    handleResizable(idx, ev) {
      this.fields[idx].width = ev.w;
      this.fields[idx].height = ev.h;
    },
    handleDraggable(attrId, ev) {
      let idx = this.fields.findIndex((f) => f.id === attrId);
      this.fields[idx].x = ev.x;
      this.fields[idx].y = ev.y;
      this.currField = this.fields[idx];
      console.log(this.fields, idx);
    },
    AddField(idx) {
      let currField = {
        ...this.getUnsetField(),
        type: this.fieldOptions[idx].Value,
        assigneeNumber: this.fieldOptions[idx].assigneeNumber
      };
      this.fields.push(currField);
      this.isSelectOpen = false;
    },
    removeField(idx) {
      this.fields.splice(idx, 1);
      this.currField = null;
    },
    saveTemplate() {
      if (this.inProgress) return;
      this.inProgress = true;
      const arrToSend = this.fields.map((field) => {
        let heightCountBefour = 0;
        let heightCount = 0;
        let pageIdx = this.images.findIndex((element, idx) => {
          heightCountBefour = heightCount;
          heightCount += this.$refs[`imgNumber${idx + 1}`][0].clientHeight;
          return field.y < heightCount;
        });

        // const page = pageIdx >= 0 ? pageIdx + 1 : 1;
        const y = pageIdx > 0 ? field.y - heightCountBefour : field.y;
        return { ...field, y };
      });
      if (!this.isUpdate) {
        const request = this.$route.query.assigneeToken ? DocumentService.updateAssigneeDocument(this.$route.params.id, this.$route.query.assigneeToken, arrToSend) : DocumentService.post(this.$route.params.id, arrToSend);
        request
          .then((templateId) => {
            this.$refs.completed.open();
            this.onTemplateUpdate();
            console.log("templateId:", templateId);
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            this.inProgress = false;
          });
      } else {
        DocumentService.updateTemplate(
          this.docIdInTemplate,
          this.$route.params.id,
          arrToSend
        )
          .then((res) => {
            console.log("res:", res);
            this.$refs.completed.open();
            setTimeout(() => {
              this.$refs.completed.close();
            }, 1500)
            this.onTemplateUpdate();
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            this.inProgress = false;
          });
      }
    },
    onAttributeClick(e, attr) {
      const element = e.target;
      this.activeAttr = attr;
      this.activeElement = element;
    },
    getAttributes(page = 1) {
      return this.fields.filter((a) => a.page === page && a.type === 'signature');
    },
    getPastSignedAttributes(page = 1) {
      if (!this.pastSignedAttributes) return [];
      return this.pastSignedAttributes.filter((a) => a.page === page);
    },
    getAttrRandomId() {
      return Math.floor(Math.random() * 10000);
    },
    closeModal() {
      this.$refs.modal.close();
      const pathBetweenBackslashes = this.$route.path.split("/");
      if (pathBetweenBackslashes[1] === "template") {
        this.fields.push({
          x: 100,
          y: 100,
          width: 150,
          height: 100,
          page: 1,
          id: this.getAttrRandomId(),
          type: "signature",
          assigneeNumber: this.queryAssigneeNumber ? this.queryAssigneeNumber : 1
        });
        this.currField = this.fields[0];
      }
      // this.goToNextField();
    },
    onTemplateUpdate() {
      window.parent.postMessage('ok', "*");
    }
  },
};
</script>

<style lang="scss" scoped>
.form-template-container .dragable-container,
.form-template-container .dragable-container .buttons-container {
  width: 1093px !important;
}

.vdr-container {
  border: 1px dotted silver;
  margin: 0 auto;
  background-color: #29e;
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  padding: 20px;
  touch-action: none;
  direction: rtl;
  text-align: center;
  width: 200px;
  opacity: 0.7;
  box-sizing: border-box;
  position: absolute;
  z-index: 3;

  &.active {
    outline: 3px solid #494949;
  }
}

.opening-modal {
  ::v-deep .sweet-content {
    font-size: 22px;
  }
}

.form-template-container {
  .dragable-container {
    width: 793px;
    margin: 40px auto;
    position: relative;

    .dropdown-container {
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      z-index: 1;
      left: 0;

      button:hover {
        background-color: rgb(185, 185, 185);
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      background-color: #2e3f50;
      padding: 5px 5px;
      position: fixed;
      top: 0;
      z-index: 20;
      width: 793px;

      button {
        display: flex;
        justify-content: space-between;
        // width: 100%;
        align-items: center;
      }

      .disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
    }

    button {
      // position: absolute;
      // transform: translateY(-40px);
      right: 0;
      height: 40px;
      background-color: #607d8b;
      color: white;
      padding: 7px 15px;
      font-size: 15px;
      border: none;
      cursor: pointer;
    }

    .select-buttons {
      color: black;
      background-color: inherit;
      width: 100%;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
  }

  .parent {
    height: auto;
    width: 793px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // position: absolute;
  }

  .all-imgs-container {
    width: 793px;
    margin: 0 auto;
    height: auto;
    display: inline-block;

    img {
      width: 100%;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    }

    .draggable-resizable-field {
      position: relative;
      width: 100%;
      height: 100%;

      .remove-button {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-30px, -30px);
        font-family: cursive;
        font-style: normal;
        font-size: 12px;
        background-color: black;
        border-radius: 50%;
        padding: 2px 6px;
        line-height: 15px;
        cursor: pointer;
        z-index: 10;
        color: white;
      }
    }

    // div div:last-of-type img{
    //       margin-bottom: 200px;
    // }
  }

  .right-side {
    width: 300px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    position: sticky;
    top: 50px;

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      border-bottom: 1px solid gray;
      padding: 10px 0;

      h2 {
        display: flex;
        width: 100%;
        font-weight: 700;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 280px;
        height: 50px;
        background-color: #607d8b;
        color: white;
        padding: 7px 15px;
        font-size: 15px;
        border: none;
        cursor: pointer;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 20px;
      padding: 10px;
      direction: ltr;

      h2 {
        font-weight: 700;
      }

      .input-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        input {
          width: 60px;
        }
      }
    }
  }
}


.ochre {
  background-color: #29e !important;
}

.light-gray {
  background-color: #607d8b !important;
}

.light-blue {
  background-color: #0060df !important;
}

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: auto auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: #2e688c;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}


@media screen and (max-width: 1100px) {
  .right-side {
    width: 30% !important;
  }

  .all-imgs-container {
    width: 70% !important;
  }

  .form-template-container .dragable-container,
  .form-template-container .dragable-container .buttons-container {
    width: 100% !important;
  }
}
</style>