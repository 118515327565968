<template>
    <div class="upload-files">
        <div class="uploads">
            <div class="upload">
                <div class="header">
                    <h1 class="upload-title"> {{ attr.fileDocumentName }}</h1>
                    <div>{{ getDocumentOwnerText(attr.fileDocumentOwner) }}</div>
                </div>
                <div class="upload-container">
                    <div v-if="isLoading" class="loading">
                        <div>
                            <font-awesome-icon spin size="2x" icon="fa-solid fa-spinner" />
                        </div>
                        <div>
                            {{ isUploading ? 'מעלה' : 'מוחק' }} מסמך...
                        </div>
                    </div>
                    <div v-else :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true"
                        @dragleave="dragging = false">
                        <div class="dropZone-info" @drag="onChange">
                            <span class="fa fa-cloud-upload dropZone-title"></span>
                            <div class="dropZone-upload-limit-info">
                                <font-awesome-icon style="transform: rotate(10deg);" size="4x" icon="fa-solid fa-camera" />
                                <font-awesome-icon style="transform: rotate(-10deg);" size="4x" icon="fa-solid fa-file" />
                            </div>
                            <span class="dropZone-title">לחץ או גרור קבצים לכאן...</span>
                        </div>
                        <input type="file" @change="onChange" accept="image/*,.pdf">
                    </div>
                </div>
                <div class="files">
                    <div v-for="file in files" :key="file.fileGuid" class="dropZone-uploaded">
                        <div class="dropZone-uploaded-info">
                            <div style="text-align: right;">
                                <a  class="dropZone-title">{{ file.fileName || file.name }}</a>
                            </div>
                            <div v-if="file.fileStatus != 2 && file.fileStatus != 3" class="buttons">
                                <!-- <font-awesome-icon @click="downloadFile(file)" color="#118dcf" icon="fa-solid fa-download" /> -->
                                <font-awesome-icon @click="removeFile(file.fileGuid, attr)" color="red" icon="fa-solid fa-trash" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import DocumentService from "@/services/DocumentService.js";
export default {
    name: 'UploadFile',
    props: ['img', 'attr', 'documentShortUrl', 'documentToken'],
    data() {
        return {
            files: [],
            dragging: false,
            isLoading: false,
            isUploading: false
        }
    },
    components: {},
    created() {
        if (this.attr.files) {
            this.files = this.attr.files;
        }
    },
    methods: {
        onChange(e) {
            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                this.dragging = false;
                return;
            }

            this.createFile(files[0]);
        },
        createFile(file) {
            // if (!file.type.match('pdf.*')) {
            //     alert('אנא בחר קובץ מסוג PDF');
            //     this.dragging = false;
            //     return;
            // }

            // if (file.size > 5000000) {
            //     alert('please check file size no over 5 MB.')
            //     this.dragging = false;
            //     return;
            // }


            const formData = new FormData();
            formData.append(file.name, file);
            this.isLoading = true;
            this.isUploading = true;
            const button = document.getElementById('submitButton');
            button.classList.add('disabled');
            DocumentService.uploadFile(this.documentShortUrl, this.documentToken, this.attr.fileDocumentType, this.attr.fileDocumentOwner, formData)
                .then((res) => {
                    // const currFile = res.data.filter((f) => f.fileName === attr.name);
                    // attr.files[i].fileGuid = 'sss';
                    const currFile = res.data.filter((f) => f.fileName === file.name)[0];
                    currFile.fileStatus = 1;
                    this.files.push(currFile);
                    const newAttr = { ...this.attr, files: this.files };
                    this.$emit('saveFile', newAttr);
                }).catch((err) => {
                    err.response.data.errorCode === '19' ? alert('חרגת מכמות הקבצים המקסימלית המותרת') : alert('קרתה תקלה, אנא נסה שנית.');
                }).finally(() => {
                    this.isLoading = false;
                    button.classList.remove('disabled');
                    this.isUploading = false;
                })
            this.dragging = false;
        },
        removeFile(id, attr) {
            if (confirm('האם אתה בטוח שברצונך למחוק קובץ זה?') === true) {
                this.isLoading = true;
                const button = document.getElementById('submitButton');
                button.classList.add('disabled');
                DocumentService.deleteFile(this.documentShortUrl, this.documentToken, id, this.attr.fileDocumentOwner)
                .then(() => {
                const idx = this.files.findIndex((file) => file.fileGuid === id);
                this.files.splice(idx, 1);
                this.$emit('deleteFile', attr);
                }).catch(() => {
                    alert('קרתה תקלה, אנא נסה שנית.');
                }).finally(() => {
                    this.isLoading = false;
                    button.classList.remove('disabled');
                })
            }
        },
        downloadFile(file) {
            const a = document.createElement('a');
            document.body.appendChild(a);
            let blob = new Blob([this.attr.file], {type: "application/json"});
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = file.fileName;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        },
        getDocumentOwnerText(type) {
            switch (type) {
                case 1:
                    return 'בן זוג רשום';
                case 2:
                    return 'בן/בת הזוג';
                case 3:
                    return 'שני בני הזוג';
                default:
                    return '';
            }
        }
    },
    computed: {
        extension() {
            return (this.file) ? this.file.name.split('.').pop() : '';
        }
    }
};
</script>

<style lang="scss" scoped>
.upload-files {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
        width: 100%;
        height: 80px;
        margin: 0 auto;
        display: flex;
        padding: 14px;
        text-align: right;
        gap: 14px;
    }

    .upload-container {
        min-height: 200px;
        display: flex;
        .existed-file {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .loading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-color: whitesmoke;
        }
    }

    .uploads {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 30px;
        background-color: #fff;
        width: 100%;
        height: 75%;
        overflow: auto;
        margin: 10px auto;
        padding: 15px;

        .upload {
            margin-bottom: 15px;
        }
    }

    .header {
        text-align: right;
        margin-bottom: 15px;
        min-height: 55px;
    }

    .upload-title {
        font-weight: bold;
        font-size: 18px;
        // min-height: 40px;
        text-align: right;
    }
}

.upload-buttons {
    position: absolute;
    bottom: 40px;
    right: 50%;
    display: flex;
    gap: 14px;

    .btn {
        padding: 20px;
        border-radius: 25px;
        background-color: red;
        color: white;
        cursor: pointer;
    }

}

.dropZone {
    // max-width: 400px;
    width: 100%;
    height: 200px;
    position: relative;
    background-color: whitesmoke;
    border: 2px dashed #eee;
}

.dropZone:hover {
    border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-upload- {
    color: #1975A0;
}

.dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropZone-title {
    color: #118dcf;
    font-size: 16px;
}

.dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropZone-upload-limit-info {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 15px;
}

.dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
}

.files {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
    margin-top: 15px;
    min-height: 52px;
}

.dropZone-uploaded {
    width: 100%;
}

.dropZone-uploaded-info {
    display: flex;
    gap: 14px;
    align-items: baseline;
    justify-content: space-between;

    svg {
        cursor: pointer;
    }
    .buttons {
        display: flex;
        gap: 10px;
    }
}

.removeFile {
    box-shadow: 0 0 5px silver;
    text-align: center;
    border: none;
    padding: 10px 5px;
    display: block;
    background-color: #cf1111;
    color: #fff;
    outline: 0;
    width: 120px;
    font-size: 16px;
    margin-top: 12px;
    cursor: pointer;
}
</style>

