<template>
  <div class="OTP__Component">
    <div class="otp-wrapper">
      <div class="otp-first-step" v-if="step === 1">
        <div class="otp-text">מסמך זה דורש תהליך זיהוי לצורך חתימה.</div>
        <div class="otp-text">
          <span>קוד זיהוי ישלח</span>
          <span v-if="otpDetails.sendingType != 0"> לכתובת מייל </span>
          <span v-if="otpDetails.sendingType == 0"> לטלפון </span>
          <div class="otp-text-ltr inline">{{ otpDetails.obfuscatedValue }}</div>
        </div>
        <div>
          <button type="button"
            :disabled="codeRequestInProgress"
            v-on:click="sendOTPRequest()">
            <span v-if="!codeRequestInProgress">שלח קוד</span>
            <span v-if="codeRequestInProgress">שולח...</span>
          </button>
        </div>
      </div>
      <div class="otp-second-step" v-if="step === 2">
        <div class="otp-title">קוד זיהוי חד פעמי</div>
        <div class="otp-text">
          <span>נא להזין את הקוד שנשלח</span>
          <span v-if="otpDetails.sendingType != 0"> לכתובת מייל </span>
          <span v-if="otpDetails.sendingType == 0"> לטלפון </span>
          <div class="otp-text-ltr inline">{{ otpDetails.obfuscatedValue }}</div>
        </div>
        <div class="otp-input-wrapper" v-bind:class="(wrongCodeError)?'incorect-code':''">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
            :placeholder="['', '', '', '', '', '']"
            @on-change="otpInputOnChange"
            @on-complete="verifyOTP"
          />
          <div class="error-wrapper" v-if="wrongCodeError">
            קוד שגוי!
          </div>
        </div>
        <div>
          <button type="button"
            :disabled="verifyInProgress"
            v-on:click="verifyOTP()">
            <span v-if="!verifyInProgress">אישור</span>
            <span v-if="verifyInProgress">מאמת...</span>
          </button>
        </div>
        <div class="otp-resend" v-on:click="resendCode()">
          <span v-if="!isAlredyResend" class="active">עדיין מחכה לדוח? שלח שוב</span>
          <span v-if="isAlredyResend">נשלח!</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import VOtpInput from 'vue3-otp-input';

export default {
  name: "OTPComponent",
  components: {
    VOtpInput,
  },
  props: ["otpDetails", "token", "shortUrl"],
  data() {
    return {
      codeRequestInProgress: false,
      isAlredyResend: false,
      otpInput: null,
      step: 1,
      verifyInProgress: false,
      wrongCodeError: false,
    }
  },
  methods: {
    clearErrors() {
      this.wrongCodeError = false;
    },
    otpInputOnChange() {
      this.clearErrors();
    },
    resendCode() {
      if (!this.isAlredyResend) {
        this.isAlredyResend = true;
        this.sendOTPRequest();
      }
    },
    sendOTPRequest() {
      this.clearErrors();
      if (!this.inProgress) {
        this.codeRequestInProgress = true;
        AuthService.sendOTPRequest(this.shortUrl, this.token)
          .then((res) => {
            if (res) {
              this.step = 2;
            }
          })
          .finally(() => {
            this.codeRequestInProgress = false;
          });
      }
    },
    verifyErrorHandler(err) {
      if (err.response && err.response.data) {
        if (err.response.data.errorCode === 13 ||
            err.response.data.errorCode === '13') {
              this.wrongCodeError = true;
        }
      }
    },
    verifyOTP(otpCode) {
      if (!this.verifyInProgress) {
        if (!otpCode) {
          otpCode = this.$refs.otpInput.otp.join('');
        }
        if (otpCode && otpCode.length === 6) {
          this.verifyInProgress = true;
          this.$store.dispatch("auth/verifyOTP", { 
            shortUrl: this.shortUrl,
            token: this.token,
            otpCode
          })
            .then(() => {
              this.$emit("afterOTP");
            })
            .catch(this.verifyErrorHandler)
            .finally(() => {
              this.verifyInProgress = false;
            });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.OTP__Component {
  position: fixed;
  top: 55px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: white;
  z-index: 4;

  .otp-wrapper {
    width: 600px;
    margin: auto;
    text-align: center;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    color: #204b70;

    div{
      text-align: center;
    }
  }

  .otp-text-ltr {
    direction: ltr;
  }

  .inline {
    display: inline-block;
  }

  .otp-first-step {
    .otp-title {
      font-size: 63px;
      margin-bottom: 25px;
    }
    .otp-text {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }

  .otp-second-step {
    .otp-title {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .otp-text {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }

  button {
    box-shadow: 0 0 5px silver;
    text-align: center;
    border: none;
    padding: 10px 5px;
    display: block;
    background-color: #118dcf;
    color: #fff;
    outline: 0;
    width: 340px;
    height: 44px;
    font-size: 16px;
    margin: 70px auto 10px auto;
    cursor: pointer;
    border-radius: 22px;

    &:active {
      background-color: #286090;
      border-color: #204d74;
    }
    &:disabled {
      background-color: grey;
      border-color: grey;
    }
  }
  
  .otp-input-wrapper {
    margin-top: 50px;
    direction: ltr;
    position: relative;

    & > div {
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .error-wrapper {
      position: absolute;
      direction: rtl;
      font-size: 22px;
      color: red;
      margin: 20px auto;
      width: 100%;
    }

    &.incorect-code input {
      border: 1px solid red;
      color: red;
    }
  }

  .otp-resend {
    margin-top: 15px;

    .active {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .otp-input {
    width: 55px;
    height: 60px;
    padding: 5px;
    margin: 0 10px;
    font-size: 36px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
@media only screen and (max-width: 600px) {
    body .OTP__Component {
          width: 100%;
          padding: 0 10px;

        .otp-wrapper {
          width: 100%;

          .otp-first-step {
            .otp-title {
              font-size: 43px;
            }

            .otp-text {
              font-size: 20px;
            }
          }

          .otp-second-step {
            .otp-title {
              font-size: 32px;
            }

            .otp-text {
              font-size: 20px;
            }
          }

          button {
            width: calc(100% - 30px);
            max-width: 300px;
          }

          .otp-input-wrapper .otp-input {
            width: 45px;
            height: 50px;
            padding: 5px;
            margin: 0 5px;
          }
      }
    }
}
</style>
