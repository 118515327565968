<template>
    <div class="image">
        <img alt="logo" :src="img">
    </div>
    <div class="send-to">
        <p>שלח קבצים אל</p>
        <h2>
            {{ documentOwner }}
        </h2>
    </div>
</template>
<script>
export default {
    props: ['img', 'documentOwner']

}
</script>
<style lang="scss">
.image {
    display: flex;
    height: 25px;

    img {
        max-width: 100%;
        display: block;
        max-height: 40px;
    }
}

.send-to {
    line-height: 150%;

    p {
        color: gray;
        font-size: 18px;
    }

    h2 {
        color: #2e94c4;
        font-size: 24px;
        font-weight: bold;
    }
}
</style>